import styled from '@emotion/styled';
import { Container } from '@layouts';
import businessAnalysis from '@assets/business-fit/business-analysis.svg';
import dedicatedAccountManager from '@assets/business-fit/dedicated-account-manager.svg';
import maintenanceIcon from '@assets/icons/maintenance.svg';
import managementSupportIcon from '@assets/icons/management-support.svg';
import ctoLeadershipIcon from '@assets/icons/cto-leadership.svg';
import productEngineersIcon from '@assets/icons/product-engineeers.svg';

export const Wrapper = styled(Container)``;
import theme from '@config/theme';

export const BusinessGrid = styled.div`
  max-width: 1000px;
  margin: auto;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 35px;
  grid-row-gap: 35px;
  padding: 40px 0;
  @media (max-width: ${theme.breakpoints.m}) {
    max-width: 600px;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, 1fr);
    font-size: ${(props) => (props.size == 'big' ? '80px' : '50px')};
  }
  @media (max-width: ${theme.breakpoints.s}) {
    max-width: 300px;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(1, 1fr);
  }
`;

export const GridItem = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  padding-bottom: 50%;
  background: ${(props) => props.theme.colors.white.lightest};
  svg {
    width: 70px;
    path {
      fill: ${(props) => props.theme.colors.primary.base};
    }
    circle {
      fill: ${(props) => props.theme.colors.primary.base};
    }
    polygon {
      fill: ${(props) => props.theme.colors.primary.base};
    }
    rect {
      fill: ${(props) => props.theme.colors.primary.base};
    }
  }
`;

export const FitForBusinessGridItemContentWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const FitForBusinessGridItemContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const FitForBusinessGridItemText = styled.p`
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  margin-top: 15px;
`;

export const BusinessAnalysisIcon = styled(businessAnalysis)``;

export const DedicatedAccountManagerIcon = styled(dedicatedAccountManager)``;

export const Free30minIcon = styled(maintenanceIcon)``;

export const FreeDesignIcon = styled(managementSupportIcon)``;

export const OneYearMaintainanceIcon = styled(ctoLeadershipIcon)``;

export const OnlinePromotionIcon = styled(productEngineersIcon)``;
