import React from 'react';
import { SectionHeading, SectionMeta } from '../Common';

import {
  Wrapper,
  BusinessGrid,
  GridItem,
  FitForBusinessGridItemContentWrapper,
  FitForBusinessGridItemContent,
  FitForBusinessGridItemText,
  BusinessAnalysisIcon,
  DedicatedAccountManagerIcon,
  Free30minIcon,
  FreeDesignIcon,
  OneYearMaintainanceIcon,
  OnlinePromotionIcon,
} from './style';

const BusinessFit = () => {
  return (
    <Wrapper>
      <SectionHeading>
        Why we are the right fit for your business
      </SectionHeading>
      <SectionMeta>
        Modern problems require modern solutions, thanks to tech geeks who are driven by the hunger of new cutting edge technology, we provide them seamlessly. Come, start your journey with us.
      </SectionMeta>
      <BusinessGrid>
        <GridItem>
          <FitForBusinessGridItemContentWrapper>
            <FitForBusinessGridItemContent>
              <Free30minIcon />
              <FitForBusinessGridItemText>
                First month maintenance<br/> is on us!
              </FitForBusinessGridItemText>
            </FitForBusinessGridItemContent>
          </FitForBusinessGridItemContentWrapper>
        </GridItem>
        <GridItem>
          <FitForBusinessGridItemContentWrapper>
            <FitForBusinessGridItemContent>
              <FreeDesignIcon />
              <FitForBusinessGridItemText>
                Product and Project <br/> Management Support
              </FitForBusinessGridItemText>
            </FitForBusinessGridItemContent>
          </FitForBusinessGridItemContentWrapper>
        </GridItem>
        <GridItem>
          <FitForBusinessGridItemContentWrapper>
            <FitForBusinessGridItemContent>
              <OneYearMaintainanceIcon />
              <FitForBusinessGridItemText>
                CTO level <br/>tech leadership from day 1
              </FitForBusinessGridItemText>
            </FitForBusinessGridItemContent>
          </FitForBusinessGridItemContentWrapper>
        </GridItem>
        <GridItem>
          <FitForBusinessGridItemContentWrapper>
            <FitForBusinessGridItemContent>
              <OnlinePromotionIcon />
              <FitForBusinessGridItemText>
                Product-minded <br/> Engineers
              </FitForBusinessGridItemText>
            </FitForBusinessGridItemContent>
          </FitForBusinessGridItemContentWrapper>
        </GridItem>
      </BusinessGrid>
    </Wrapper>
  );
};

export default BusinessFit;
